// import van11yAccessibleTabPanelAria from "van11y-accessible-tab-panel-aria";
// import ShowMore from "./components/show-more";
// import LinkNextElement from "./components/link-next-element";

import Header from "./components/header";
import Slider from "./components/slider";
import NavMobile from "./components/nav-mobile";
import Jumpmenu from "./components/jumpmenu";
import LuchtAccordion from "./components/accordion";

// https://kenwheeler.github.io/slick/

new Header();
new NavMobile();
new Slider('.js--slider');
new Jumpmenu('.js--jumpmenu');
new LuchtAccordion('.js--accordion');

document.documentElement.classList.replace('no-js', 'js');

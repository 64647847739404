import animateScrollTo from 'animated-scroll-to';

export default class Jumpmenu {

  constructor(selector) {
    this.wrapper = document.querySelector(selector);
    if (!this.wrapper) {
      return;
    }

    this.links = document.querySelectorAll('.jumpmenu--link');
    this.targets = document.querySelectorAll('.jumpmenu--target');
    // this.header = document.getElementById('header');
    // this.body = document.querySelector('body');

    this.prepare();
  }


  prepare() {
    for (let i = 0; i < this.links.length; ++i) {

      if (!this.links[i].dataset.jumpmenuinitialized) {
        let target = document.querySelector(this.links[i].hash + '-target');
        this.links[i].addEventListener('click', (event) => {
          // event.preventDefault();
          // if (target && this.body.classList.contains('is-home')) {
          if (target) {
            this.aimToTarget(this.links[i], target);
          }
        });
      }
      this.links[i].dataset.jumpmenuinitialized = true;
    }


    if (window.location.hash) {
      let _link = document.querySelector('.navigation--header-link[href="' + window.location.hash + '"]');
      let _target = document.querySelector(window.location.hash + '-target');
      if (_target) {
        this.aimToTarget(_link, _target);
      }
    }
    else {
      // window.scrollTo(0, 0);
    }

  }


  aimToTarget(link, target) {
    if (target) {
      setTimeout(() => {
        if (link) {
          this.activateLink(link);
        }
        this.scrollTo(target);
      }, 10);
    }
  }


  activateLink(link) {
    for (let i = 0; i < this.links.length; ++i) {
      this.links[i].classList.remove('is-active');
    }
    if (link) {
      link.classList.add('is-active');
    }
  }


  scrollTo(target) {
    const _options = {
      speed: 500,
      minDuration: 250,
      maxDuration: 750,
      verticalOffset: 0
    };

    setTimeout(() => {
      animateScrollTo(target.offsetTop, _options).then((hasScrolledToPosition) => {
        // // remove hash
        // history.pushState("", document.title, window.location.pathname + window.location.search);
      });
    }, 500);
  }

}
